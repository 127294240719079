import React from "react"
import Layout from "../components/layout"
import HeroStatic from "../components/heroStatic"
const More = () => {
  return (
    <Layout>
      <HeroStatic title="more" />
      <article>
        <div>this is the More page</div>
      </article>
    </Layout>
  )
}
export default More
